import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Header } from '../../components/Header/Header';
import { ProfileDrawer } from '../../components/Header/ProfileDrawer';
import NavBar from '../../components/NavBar';
import { useAuth } from '../../contexts/authContext';
import { Match, onUserMatchesSnapshot } from '../../firebase/api';

export default function RootPage() {
  const { user } = useAuth();
  const { pathname, state: locationState } = useLocation();
  const navigate = useNavigate();
  const [userMatches, setUserMatches] = useState<Match[] | undefined>(
    undefined,
  );

  const badgeCount = useMemo(() => {
    return (
      userMatches?.filter(match => {
        const { status, winner, submitter } = match;
        const isWinner = user?.id === winner.id;
        const isSubmitter = user?.id === submitter?.id;
        const isConfirmButtonDisabled =
          isSubmitter || (isWinner && submitter === null);

        return status === 'pending' && !isConfirmButtonDisabled;
      }).length ?? 0
    );
  }, [user?.id, userMatches]);

  useEffect(() => {
    const fetchUserMatches = async () => {
      if (user?.id === undefined) return;

      const unsubscribe = onUserMatchesSnapshot(user.id, setUserMatches);

      return unsubscribe;
    };

    fetchUserMatches();
  }, [user?.id]);

  return (
    <>
      <Header badgeCount={badgeCount} />
      <Box sx={{ pb: 6 }}>
        <Outlet />
      </Box>
      <NavBar />
      <ProfileDrawer
        open={pathname === '/profile'}
        onClose={() => navigate(locationState?.prevPathname ?? '/')}
      />
    </>
  );
}
